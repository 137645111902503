import Graph from "graphology";
import { BaseElementDict } from "../../types.d";


export const makeIntermediaryIndexForNode = (id: string, elements: BaseElementDict, graph: Graph): string[] => {
    const intermap = new Map();

    const recurse = (id: string, breadcrumbs: string[]) => {

        // Only traverse IKT nodes, this validation is a bit hacky, and should be refined at rawPostProcessing
        // const targetIsValidIntermediary = elements[id].lvl1 && elements[id].lvl1 !== 'U6'
        const targetIsValidIntermediary = elements[id].u === 7

        // IST successfully reached, apply accumulated path breadcrumbs to intermediaries
        if (elements[id].clss === 'ist') {
            for (const interId of breadcrumbs) {
                if (!intermap.has(interId)) {
                    intermap.set(interId, true);
                }
            }
            // break recursion.

        } else if (targetIsValidIntermediary) {
            graph.forEachOutNeighbor(id, (targetId) =>
                recurse(targetId, [...breadcrumbs, id])
            );
        }
    };


    graph.forEachOutNeighbor(id, (targetId) => {
        recurse(targetId, []);
    });

    return Array.from(intermap.keys());
};