import { ElementClass, RawLine } from "../../types.d";

export default function getLane(line: RawLine, clss: ElementClass): number {

    if (clss === 'ist') {
        return parseInt(line.desc.substring(10, 12))
    }

    if (clss === 'vit') {
        return parseInt(line.desc.substring(4, 6))
    }

    if (clss === 'leveranse') {
        return line.lvl2
    }

     return line.lane || 0

}