import { Dependency } from "../types.d";

export default function processCSVIndep(input: any): Dependency[] {

    let output: Dependency[] = []

    const inputArray = input.split('\n');
    inputArray.forEach((arr: string) => {
        const parts = arr.split(',')
        if (parts[0] && parts[1] && parts[2] && parts[0] !== 'From') {
            output.push({ from: parts[0], to: parts[1], type: parts[2] })
        }
    })

    return output

}