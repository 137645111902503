import { BaseElementDict, byggstruktur, Lvl1, MasterIndex, u7c, View, YIndex } from "../types.d";

export const laneHeight = 12
export const sectionTopMargin = laneHeight
export const blockTopMargin = laneHeight / 2
export const blockBottomMargin = laneHeight / 2
export const sectionBottomMargin = laneHeight * 2

export default function processYData(elements: BaseElementDict, masterIndex: MasterIndex, visible: Lvl1[], view: View, viewBygg: string): YIndex {

    const yIndex: YIndex = {}

    let acc = 180 // timeline margin
    let istMax = 0
    let vitMax = 0

    const showIST = view !== 'byggplan' || !!viewBygg

    // if (view === 'istplan') {

    //     const eie1 = Object.values(elements).find(e => e.eie === 1 && e.clss === 'eierskifte')
    //     const eie2 = Object.values(elements).find(e => e.eie === 2 && e.clss === 'eierskifte')
    //     const eie3 = Object.values(elements).find(e => e.eie === 3 && e.clss === 'eierskifte')

    //     if (!eie1 || !eie2 || !eie3) { }

    //     else {
    //         [eie1, eie2, eie3].forEach(eierskifte => {

    //             acc = acc + (blockTopMargin * 2)
    //             const blockTop = acc
    //             yIndex[eierskifte.id] = { top: blockTop }

    //             structuredClone(masterIndex.eierskifte[eierskifte.eie!])
    //                 .sort((a, b) => {
    //                     return elements[a].end < elements[b].end ? -1 : 1
    //                 })
    //                 .forEach((elemId, i) => {
    //                     yIndex[elemId] = { top: blockTop + ((i + 1) * laneHeight) }
    //                 })


    //             acc = acc + (masterIndex.eierskifte[eierskifte.eie!].length + 1) * laneHeight
    //             const blockBottom = acc + (blockBottomMargin)
    //             yIndex[eierskifte.id]['bottom'] = blockBottom
    //             acc = blockBottom

    //         })

    //         acc = acc + (2 * laneHeight)

    //         structuredClone(masterIndex.filters.istPlanRelated)
    //             .sort((a, b) => {
    //                 return elements[a].end < elements[b].end ? -1 : 1
    //             })
    //             .forEach(elemId => {
    //                 yIndex[elemId] = { top: acc }
    //                 acc = acc + laneHeight
    //             })
    //     }
    // }

    if (showIST) {
        masterIndex.clss['ist'].forEach(id => {
            const elem = elements[id]
            const { lane = 0 } = elem
            if (elem) {
                const y = acc + laneHeight * lane
                yIndex[id] = { top: y }
                istMax = y > istMax ? y : istMax
            }
        })

        masterIndex.clss['vit'].forEach(id => {
            const elem = elements[id]
            const { lane = 0 } = elem
            if (elem) {
                const y = acc + laneHeight * lane
                yIndex[id] = { top: y }
                vitMax = vitMax > y ? vitMax : y
            }
        })

        acc = Math.max(istMax, vitMax) + laneHeight * 3
    }


    if (view === 'byggplan') {

        if (viewBygg) {

            const byggId = byggstruktur.find(byggstr => byggstr.id === viewBygg)!.id

            const byggActivities = structuredClone(masterIndex.bygg[byggId])
                .sort((a, b) => {
                    return elements[a].end < elements[b].end ? -1 : 1
                })

                const byggRelatedActivities = structuredClone(masterIndex.byggRelated[byggId])
                .sort((a, b) => {
                    return elements[a].end < elements[b].end ? -1 : 1
                })


                byggRelatedActivities.forEach(elemId => {
                    yIndex[elemId] = { top: acc }
                    acc = acc + laneHeight
            })


            let acc1 = acc
            let acc2 = acc

            acc2 = acc2 + (blockTopMargin * 2)
            const blockTop = acc2
            yIndex[byggId] = { top: blockTop }

            acc2 = acc2 + laneHeight * 6
            acc1 = acc1 + (blockTopMargin * 2) + laneHeight * 1


            byggActivities.forEach(elemId => {
                if (elements[elemId].u === 7) {
                    yIndex[elemId] = { top: acc1 }
                    acc1 = acc1 + laneHeight
                }
                else {
                    yIndex[elemId] = { top: acc2 }
                    acc2 = acc2 + laneHeight
                }
            })


            const blockBottom = Math.max((blockTop + (laneHeight * 3)), (acc2 + (blockBottomMargin)))
            yIndex[byggId]['bottom'] = blockBottom
            acc2 = blockBottom


        }

        else {
            acc = acc + 200

            structuredClone(byggstruktur)
                .sort((a, b) => {
                    return a.lane < b.lane ? -1 : 1
                })
                .forEach(({ id }, i) => {

                    acc = acc + (blockTopMargin * 2)
                    const blockTop = acc
                    yIndex[id] = { top: blockTop }

                    acc = acc + laneHeight * 2

                    structuredClone(masterIndex.filters.byggspesifikke)
                        .filter(elId => elements[elId].bygg === id)
                        .sort((a, b) => {
                            return elements[a].end < elements[b].end ? -1 : 1
                        })
                        .forEach(elemId => {
                            yIndex[elemId] = { top: acc }
                            acc = acc + laneHeight
                        })


                    const blockBottom = Math.max((blockTop + (laneHeight * 3)), (acc + (blockBottomMargin)))
                    yIndex[id]['bottom'] = blockBottom
                    acc = blockBottom
                })
        }


    }



    if (view === 'satplan-chron') {
        acc = acc - laneHeight * 8
        structuredClone(masterIndex.filters.satPlan)
            .sort((a, b) => {
                return elements[a].end < elements[b].end ? -1 : 1
            })
            .forEach(elemId => {
                yIndex[elemId] = { top: acc }
                acc = acc + laneHeight
            })
    }


    else if (view === 'extended') {
        const lvl1Sorted = structuredClone(u7c).sort((a, b) => (a < b ? -1 : 1))
        lvl1Sorted.forEach(lvl1 => {
            if (visible.includes(lvl1 as Lvl1)) {

                const sectionTop = acc

                yIndex[lvl1] = { top: sectionTop }
                acc = acc + sectionTopMargin

                // Top field for elements not in lvl2

                const elementsNotInLvl2 = masterIndex.children[lvl1]
                    .filter(id => elements[id].clss !== 'leveranse' && !elements[id].lvl2)

                if (elementsNotInLvl2.length > 0) {
                    acc = acc + blockTopMargin
                    let blockLaneMax = 0
                    elementsNotInLvl2.forEach(elemId => {
                        const { lane = 0 } = elements[elemId]
                        blockLaneMax = Math.max(lane, blockLaneMax)
                        const elemTop = acc + (lane * laneHeight)
                        yIndex[elemId] = { top: elemTop }
                    })
                    acc = acc + (blockLaneMax + 1) * laneHeight
                }

                // Lvl2s

                const lvl2IdsSorted = masterIndex.children[lvl1]
                    .filter(id => elements[id].clss === 'leveranse')
                    .sort((a, b) => elements[a].lvl2! < elements[b].lvl2! ? -1 : 1)

                lvl2IdsSorted.forEach(lvl2Id => {
                    const { lvl2 } = elements[lvl2Id]
                    acc = acc + blockTopMargin
                    const blockTop = acc
                    yIndex[lvl2Id] = { top: blockTop }
                    let blockLaneMax = 0

                    const combinedId = `${lvl1}-${lvl2}`

                    // masterIndex.children[combinedId].forEach((elemId, i) => {
                    //     const j = i + 1
                    //     blockLaneMax = Math.max(j, blockLaneMax)
                    //     const elemTop = acc + (j * laneHeight)
                    //     yIndex[elemId] = { top: elemTop }
                    // })

                    structuredClone(masterIndex.children[combinedId])
                        .sort((a, b) => {
                            return elements[a].start < elements[b].start ? -1 : 1
                        })
                        .forEach((elemId, i) => {
                            const j = i + 1
                            blockLaneMax = Math.max(j, blockLaneMax)
                            const elemTop = acc + (j * laneHeight)
                            yIndex[elemId] = { top: elemTop }
                        })

                    acc = acc + ((blockLaneMax + 1) * laneHeight)
                    const blockBottom = acc + blockBottomMargin
                    yIndex[lvl2Id]['bottom'] = blockBottom
                    acc = blockBottom
                })

                const sectionBottom = Math.max(acc, sectionTop + 100)
                yIndex[lvl1]['bottom'] = sectionBottom
                acc = sectionBottom + sectionBottomMargin

            }
        })
    }



    return yIndex
}


