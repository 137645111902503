import { ElementClass, RawLine } from "../../types.d";

export default function getLabel(line: RawLine, clss: ElementClass): string {

    if (clss === 'kib') {
        return line.desc.includes('3') ? "KIB 2+3" : "KIB 1"
    }

    if (clss === 'eierskifte') {
        const trimmed = line.desc.trim()
        return trimmed.substring(trimmed.length - 12, trimmed.length)
    }

    return ""

}