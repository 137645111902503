import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { leftBar } from '../../Layout';
import { selectElements, selectXIndex, selectYIndex } from '../../slice';



export default function ElementBlock(props: { id: string }) {

    const yIndex = useSelector(selectYIndex)
    const xIndex = useSelector(selectXIndex)
    const elements = useSelector(selectElements)
    const { id } = props

    if (!(id in xIndex && id in yIndex)) {
        return null
    }


    const { clss, alt, lane, desc, lvl2 } = elements[id]
    const { start, end } = xIndex[id]
    const computedStart = clss === 'eierskifte' ? 0 : start
    const width = end - computedStart + 10
    const { top, bottom } = yIndex[id] 
    const height = (bottom || top) - top
    const label = alt || desc
    const prefix = label.substring(0, 3) === lvl2 ? '' : lvl2

    return (
        <React.Fragment>
            
            <Box
                position="absolute"
                left={`${computedStart}px`}
                height={`${height}px`}
                width={`${width}px`}
                top={`${top}px`}
                border="1px solid"
                borderColor={"gray.300"}
                bg="whiteAlpha.700"
                shadow="sm"
                borderRadius={3}
                zIndex={-1}
                // bgGradient='linear(to-b, gray.100 0%, whiteAlpha.700 10px)'
            />
            <Text
                position="absolute"
                left={`${Math.max(computedStart, leftBar) + 6}px`}
                top={`${top}px`}
                fontSize={`${10}px`}
                color="gray.500"
                fontWeight={600}
            >{prefix} {label}</Text>
        </React.Fragment>
    )

}

