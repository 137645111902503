import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import processYData from "../func/processYData";
import { selectElements, selectMasterIndex, selectView, selectViewBygg, selectVisible, setYIndex } from "../slice";


export default function useRecalculateY() {

    const dispatch = useDispatch()
    const elements = useSelector(selectElements)
    const masterIndex = useSelector(selectMasterIndex)
    const visible = useSelector(selectVisible)
    const view = useSelector(selectView)
    const viewBygg = useSelector(selectViewBygg)

    useEffect(() => {

        if (Object.keys(elements).length > 0) {
            const yIndex = processYData(elements, masterIndex, visible, view, viewBygg)
            dispatch(setYIndex(yIndex))
        }

    }, [dispatch, elements, masterIndex, visible, view, viewBygg])


    return null

}

