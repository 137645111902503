import React from 'react';
import { useSelector } from 'react-redux';
import { selectElements, selectView } from '../../slice';
import Element from './Element';
import ElementActivity from './ElementActivity';
import ElementBlock from './ElementBlock';
import ElementSection from './ElementSection';
import ElementSectionBygg from './ElementSectionBygg';


export default function Elements() {

    const elements = useSelector(selectElements)
    const view = useSelector(selectView)

    return (
        <React.Fragment>
            {Object.keys(elements).map(id => {
                const isMilestone = elements[id].start === elements[id].end
                if (elements[id].clss === 'kib') {
                    return null
                }
                else if (elements[id].clss === 'eierskifte') {
                    if (view === 'istplan') {
                        return <ElementBlock key={id} id={id} />
                    }
                    else {
                        return null
                    }
                }
                else if (elements[id].clss === 'u7c') {
                    return !['standard', 'extended'].includes(view) ? null : <ElementSection key={id} id={id} />
                }
                else if (elements[id].clss === 'bygg') {
                    return !['byggplan'].includes(view) ? null : <ElementSectionBygg key={id} id={id} />
                }
                else if (elements[id].clss === 'leveranse') {
                    return !['standard', 'extended'].includes(view) ? null : <ElementBlock key={id} id={id} />
                }
                else if (['test-do', 'st-sit-do', 'sat-do'].includes(elements[id].clss) || !isMilestone) {
                    return <ElementActivity key={id} id={id} />
                }
                else {
                    return (
                        <Element key={id} id={id} />
                    )
                }

            })}
        </React.Fragment>
    )

}

