import { createSlice } from "@reduxjs/toolkit"
import { DateTime } from "luxon"
import { dependencies } from "./fixtures/deps"
import { spec } from "./fixtures/spec"
import { BaseElementDict, Dependency, LookAhead, Lvl1, MasterIndex, Modal, RawLineDict, SpecLine, SpecLineDict, View, XIndex, YIndex, byggstruktur, defaultMasterIndex, lvl1 } from "./types.d"

export interface Action<T> {
    type: string, payload: T
}

export interface DataState {
    rawData: RawLineDict,
    rawOrder: string[], // The order of lines in Grid.
    spec: SpecLineDict,
    specUpdated: string,
    elements: BaseElementDict
    elementsLoaded: boolean,
    dependencies: Dependency[],
    xIndex: XIndex,
    yIndex: YIndex,
    masterIndex: MasterIndex,
    x: {
        viewportWidth: number,
        scaleX: number,
        timelineStart: [number, number, number]
        timelineEnd: [number, number, number],
    },
    layout: {
        modal: Modal,
        visible: Lvl1[],
        lookAhead: LookAhead,
        showDates: boolean,
        showDeps: boolean,
        showGraph: boolean,
        showProgress: boolean,
        view: View,
        viewBygg: string,
        selectedId: string,
    },
}

export const initialDataState: DataState = {
    rawData: {},
    rawOrder: [],
    spec: spec,
    specUpdated: '',
    elements: {},
    elementsLoaded: false,
    dependencies: dependencies,
    masterIndex: structuredClone(defaultMasterIndex),
    xIndex: {},
    yIndex: {},
    x: {
        viewportWidth: 0,
        scaleX: 1,
        timelineStart: [2024, 10, 1],
        timelineEnd: [2025, 12, 15],
    },
    layout: {
        modal: '',
        visible: [ lvl1[0] ],
        lookAhead: 0,
        showDeps: false,
        showGraph: false,
        showProgress: false,
        showDates: false,
        view: 'satplan-chron',
        viewBygg: byggstruktur[0].id,
        selectedId: '',
    },
}

export const dataSlice = createSlice({
    name: 'data',
    initialState: initialDataState,
    reducers: {
        setRawData: (state, action: Action<any>) => { state.rawData = action.payload.data; state.rawOrder = action.payload.order },
        setSpec: (state, action: Action<SpecLineDict>) => { state.spec = action.payload; state.specUpdated = DateTime.now().toISO() },
        setElements: (state, action: Action<BaseElementDict>) => { state.elements = action.payload },
        setElementsLoaded: (state, action: Action<boolean>) => { state.elementsLoaded = action.payload },
        setDependencies: (state, action: Action<Dependency[]>) => { state.dependencies = action.payload },
        setViewportWidth: (state, action: Action<number>) => { state.x.viewportWidth = action.payload },
        setScaleX: (state, action: Action<number>) => { state.x.scaleX = action.payload },
        setXIndex: (state, action: Action<XIndex>) => { state.xIndex = action.payload },
        setYIndex: (state, action: Action<YIndex>) => { state.yIndex = action.payload },
        tmpToggleScale: (state, action: Action<number>) => { state.x.scaleX = state.x.scaleX === 1 ? 2 : 1 },
        setModal: (state, action: Action<Modal>) => { state.layout.modal = action.payload },
        setVisible: (state, action: Action<Lvl1[]>) => { state.layout.visible = action.payload },
        setLookAhead: (state, action: Action<LookAhead>) => { state.layout.lookAhead = action.payload },
        setShowDeps: (state, action: Action<boolean>) => { state.layout.showDeps = action.payload },
        setShowDates: (state, action: Action<boolean>) => { state.layout.showDates = action.payload },
        setShowGraph: (state, action: Action<boolean>) => { state.layout.showGraph = action.payload },
        setShowProgress: (state, action: Action<boolean>) => { state.layout.showProgress = action.payload },
        setView: (state, action: Action<View>) => { state.layout.view = action.payload },
        setViewBygg: (state, action: Action<string>) => { state.layout.viewBygg = action.payload },
        setSelectedId: (state, action: Action<string>) => { state.layout.selectedId = action.payload },
        setMasterIndex: (state, action: Action<MasterIndex>) => { state.masterIndex = action.payload },

    },
})

export const {
    setRawData,
    setSpec,
    setElements,
    setElementsLoaded,
    setViewportWidth,
    setShowDates,
    setDependencies,
    setScaleX,
    setXIndex,
    setYIndex,
    tmpToggleScale,
    setModal,
    setVisible,
    setLookAhead,
    setShowDeps,
    setShowGraph,
    setShowProgress,
    setView,
    setViewBygg,
    setSelectedId,
    setMasterIndex,
} = dataSlice.actions

export default dataSlice.reducer


interface StateWithData {
    data: DataState
}

export const selectRawData = (state: StateWithData) => state.data.rawData
export const selectRawOrder = (state: StateWithData) => state.data.rawOrder
export const selectElements = (state: StateWithData) => state.data.elements
export const selectElementsLoaded = (state: StateWithData) => state.data.elementsLoaded
export const selectDependencies = (state: StateWithData) => state.data.dependencies
export const selectViewportWidth = (state: StateWithData) => state.data.x.viewportWidth
export const selectScaleX = (state: StateWithData) => state.data.x.scaleX
export const selectTimelineStart = (state: StateWithData) => state.data.x.timelineStart
export const selectTimelineEnd = (state: StateWithData) => state.data.x.timelineEnd
export const selectXIndex = (state: StateWithData) => state.data.xIndex
export const selectYIndex = (state: StateWithData) => state.data.yIndex
export const selectModal = (state: StateWithData) => state.data.layout.modal
export const selectSpec = (state: StateWithData) => state.data.spec
export const selectSpecUpdated = (state: StateWithData) => state.data.specUpdated
export const selectVisible = (state: StateWithData) => state.data.layout.visible
export const selectLookAhead = (state: StateWithData) => state.data.layout.lookAhead
export const selectShowDates = (state: StateWithData) => state.data.layout.showDates
export const selectShowDeps = (state: StateWithData) => state.data.layout.showDeps
export const selectShowGraph = (state: StateWithData) => state.data.layout.showGraph
export const selectShowProgress = (state: StateWithData) => state.data.layout.showProgress
export const selectView = (state: StateWithData) => state.data.layout.view
export const selectViewBygg = (state: StateWithData) => state.data.layout.viewBygg
export const selectSelectedId = (state: StateWithData) => state.data.layout.selectedId
export const selectMasterIndex = (state: StateWithData) => state.data.masterIndex


export const tmpSelectX = (state: StateWithData) => {
    let ret: SpecLineDict = {}
    Object.entries(state.data.spec).forEach(([key, val]) => {
        let newVal: SpecLine = { ...val }
        const seq = (state.data.rawData[key] || {}).seq || key
        ret[seq] = newVal
        // if (val.parent) {
        //     const items = val.parent.split('-')
        //     newVal['lvl1'] = items[0] as Lvl1
        //     if (items[1]) {
        //         newVal['lvl2'] = items[1] as Lvl2
        //     }
        //     delete newVal['parent']
        // }
        // ret[key] = newVal
    })
    return ret
}

export const selectMaxY = (state: StateWithData) => {
    let max = 0
    Object.values(state.data.yIndex).forEach(({top, bottom}) => {
        max = Math.max(max, top, bottom || 0)
    })
    return max
}

