import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectView } from './slice';

export default function ByggImage() {

    const view = useSelector(selectView)

    if (view === 'byggplan') {
        return (
            <Image
                src={"assets/modell.png"}
                position="absolute"
                top="80px"
                left="40px"
                width={"300px"}
                zIndex={-2}
            />
        )
    }

    else {
        return null
    }


}