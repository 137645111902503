import { Box, Text, Tooltip } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { leftBar } from '../../Layout';
import { laneHeight } from '../../func/processYData';
import { selectElements, selectShowDates, selectShowProgress, selectTimelineStart, selectView, selectXIndex, selectYIndex, setSelectedId } from '../../slice';
import ElementProgress, { progressWidth } from './ElementProgress';



export default function ElementActivity(props: { id: string }) {

    const xIndex = useSelector(selectXIndex)
    const yIndex = useSelector(selectYIndex)
    const elements = useSelector(selectElements)
    const showProgress = useSelector(selectShowProgress)
    const view = useSelector(selectView)
    const startRaw = useSelector(selectTimelineStart)
    const dispatch = useDispatch()
    const showDates = useSelector(selectShowDates)

    const { id } = props

    const onClick = (e: any) => {
        e.stopPropagation()
        dispatch(setSelectedId(id))
    }

    if (!(id in xIndex && id in yIndex)) {
        return null
    }

    const { clss, alt, lane, desc, lvl1 = '', lvl2 = '', start: startTime, end: endTime } = elements[id]
    const { start, end } = xIndex[id]
    const computedStart = Math.max(start, leftBar + (showProgress ? progressWidth : 0) + 10)
    const width = end - computedStart
    const height = laneHeight - 2
    const { top } = yIndex[id]

    const lvl2Elem = !lvl2 || view === 'standard' ? null : (Object.values(elements).find(el => el.lvl1 === lvl1 && el.lvl2 === lvl2 && el.clss === 'leveranse'))
    const lvl2Label = lvl2Elem ? elements[lvl2Elem.id].desc : ''

    const bgGradientColor = lvl1 === 'U6' ? 'cyan.400, cyan.200, cyan.300'
        : clss === 'sat-do' ? 'green.300, green.100, green.200'
            : clss === 'st-sit-do' ? 'blue.300, blue.100, blue.200'
                : 'gray.400, gray.200, gray.300'


    const label = alt || desc


    return (
        <React.Fragment>

            {showProgress ? (
                <ElementProgress id={id} x={computedStart} y={top} />
            ) : null}

            <Box
                position="absolute"
                left={`${computedStart}px`}
                height={`${height}px`}
                width={`${width}px`}
                bgGradient={`linear(to-b, ${bgGradientColor})`}
                top={`${top + 1}px`}
                boxSizing="border-box"
                borderColor="gray.300"
                onClick={onClick}
                borderRadius={"2px"}
            />

            {start !== computedStart ? (
                <Box
                    position="absolute"
                    left={`${computedStart}px`}
                    height={`${height}px`}
                    width={`${26}px`}
                    top={`${top + 1}px`}
                    boxSizing="border-box"
                    bgGradient={"linear(to-r, white, whiteAlpha.50)"}
                    onClick={onClick}
                />
            ) : null}

            <Text
                position="absolute"
                left={`${end + 2}px`}
                top={`${top - 1}px`}
                fontSize={`${10}px`}
                color="gray.700"
                whiteSpace={"nowrap"}
            >

                {showDates ? (
                    <Text
                        as="span"
                        fontWeight={200}
                    >{`${DateTime.fromISO(startTime).toLocaleString()}-${DateTime.fromISO(endTime).toLocaleString()}: `}
                    </Text>
                ) : null}

                {view !== 'extended' ? (
                    <Tooltip
                        label={lvl2Label}
                        color="white"
                        fontSize={'xs'}>
                        <Text
                            as="span"
                        >{lvl1} {lvl2} - </Text>
                    </Tooltip>
                ) : null}
                <Tooltip
                    label={`${DateTime.fromISO(startTime).toLocaleString()} - ${DateTime.fromISO(endTime).toLocaleString()}`}
                    color="white"
                    fontSize={'xs'}>
                    <Text
                        as="span"
                    >{label}</Text>
                </Tooltip>
            </Text>


        </React.Fragment>
    )

}

