import { planwebWBSTo75xx, RawLineDict } from "../types.d";


export default function postProcessRawData(lineDictInput: RawLineDict, order: string[]): RawLineDict {

    let lineDict: RawLineDict = {}
    let curr75xx = ""
    let curr75xxPrefix = ""
    let currLvl2 = 0
    let currLPrefix = ""


    order.forEach(seq => {

        const line = lineDictInput[seq]
        let next = {
            ...line,
            lvl1: "",
            lvl2: 0,
            isLvl2: false,
            lane: 1,
            u: 0
        }

        if (line.WBS.startsWith("2.5.")) {
            next.lvl1 = "U6" // TODO: Deprecate
            next.u = 6
        }
        else if (line.WBS.startsWith("2.7.")) {
            next.u = 7
        }

        // Er det enn curr75xx? Hvis ikke, se om denne er det
        if (!curr75xx) {
            if (planwebWBSTo75xx[line.WBS]) {
                curr75xx = planwebWBSTo75xx[line.WBS]
                curr75xxPrefix = line.WBS
                currLPrefix = ""
                currLvl2 = 0
                // next.lvl1 = curr75xx
            }
        }

        // Er det en L? Hvis ikke, se om denne er det
        else if (currLvl2 === 0) {
            if (line.seq.startsWith("p")) {
                currLvl2 = 1
                next.lvl1 = curr75xx
                next.lvl2 = currLvl2
                currLPrefix = line.WBS
                next.isLvl2 = true
            }
        }

        // Ellers - curr75xx og currL er satt
        else {

            // hvis WBS stemmer med currLPrefix - typ som aktivitet
            if (line.WBS.startsWith(currLPrefix)) {
                next.lvl1 = curr75xx
                next.lvl2 = currLvl2
                next.isLvl2 = false

            }

            // Hvis ikke WBS stemmer med currLPrefix, men med 75xx sjekk om ny L eller skip
            else if (!line.WBS.startsWith(currLPrefix) && line.WBS.startsWith(curr75xxPrefix)) {
                if (line.seq.startsWith("p")) {
                    currLvl2 = currLvl2 + 1
                    currLPrefix = line.WBS
                    next.lvl1 = curr75xx
                    next.lvl2 = currLvl2
                    next.isLvl2 = true
                }
            }

            // Hvis ikke WBS stemmer med currLPrefix eller med 75xx, unset begge.
            else if (!line.WBS.startsWith(currLPrefix) && !line.WBS.startsWith(curr75xxPrefix)) {
                if (planwebWBSTo75xx[line.WBS]) {
                    curr75xx = planwebWBSTo75xx[line.WBS]
                    curr75xxPrefix = line.WBS
                    currLPrefix = ""
                    currLvl2 = 0
                    // next.lvl1 = curr75xx
                }
                else {
                    curr75xx = ""
                    curr75xxPrefix = ""
                    currLPrefix = ""
                    currLvl2 = 0
                }

            }

        }

        lineDict[seq] = next


    })

    return lineDict

}