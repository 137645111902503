import { Box, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { leftBar } from '../../Layout';
import { selectElements, selectViewportWidth, selectYIndex } from '../../slice';
import { u7cDict } from '../../types.d';



export default function ElementSection(props: { id: string }) {

    const yIndex = useSelector(selectYIndex)
    const elements = useSelector(selectElements)
    const viewWidth = useSelector(selectViewportWidth)
    const { id } = props

    if (!(id in yIndex)) {
        return null
    }

    const { clss, alt, lane } = elements[id]
    const { desc } = elements[id]
    const { top, bottom } = yIndex[id]
    const label = u7cDict[id].navn
    const height = (bottom || top + 20) - top

    return (
        <React.Fragment>

            <Box
                position="absolute"
                left={`${leftBar}px`}
                // height={`${config.diamondSize}px`}
                width={`${viewWidth - leftBar}px`}
                top={`${top}px`}
                height={`${height}px`}
            // bgGradient="linear(to-br, gray.100 0%, whiteAlpha.50 30%)"
            // bgGradient="linear(to-r, gray.100 0%, whiteAlpha.50 7%)"

            />


            <Stack
                bg="gray.600"
                position="absolute"
                left={`${leftBar}px`}
                top={`${top}px`}
                height={`${leftBar}px`}
                width={`${height}px`}
                zIndex={3}
                transform={`rotate(90deg)`}
                transformOrigin={"top left"}
                justify={"center"}
                p="2"
                whiteSpace={"nowrap"}
                overflow={"hidden"}
            >

                <Text
                    fontSize={`xs`}
                    fontWeight={600}
                    color="white"
                >{id} {label} </Text>

            </Stack>

        </React.Fragment>
    )

}

