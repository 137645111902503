import { useSelector } from "react-redux"
import { config } from "../../config"
import { selectElements, selectMasterIndex, selectSelectedId, selectShowDeps, selectView, selectViewBygg, selectVisible, selectXIndex, selectYIndex } from "../../slice"




export default function ElementEdges() {

    const masterIndex = useSelector(selectMasterIndex)
    const visible = useSelector(selectVisible)
    const elements = useSelector(selectElements)
    const xIndex = useSelector(selectXIndex)
    const yIndex = useSelector(selectYIndex)
    const istIds = masterIndex.clss['ist'] || []
    const showDeps = useSelector(selectShowDeps)
    const selectedId = useSelector(selectSelectedId)
    const view = useSelector(selectView)
    const viewBygg = useSelector(selectViewBygg)

    let paths: { id: string, d: string, display: 'highlight' | 'lowlight' | 'normal' }[] = []
    let maxY = 0

    if (!showDeps) {
        return null
    }


    const subset = view === 'byggplan' && !!viewBygg ? Object.keys(yIndex) // If single bygg - get edges for all nodes in view.
        : istIds

    subset.forEach((id: string) => {


        (masterIndex.inbounds[id] || []).forEach(fromId => {

            const elem = elements[fromId]

            if (elem // && 
                //  ((elem.lvl1) || !elem.lvl1)
                && Object.keys(yIndex).includes(fromId)
                && Object.keys(yIndex).includes(id)
                && Object.keys(xIndex).includes(fromId)
                && Object.keys(xIndex).includes(id)
            ) {

                const linkType = masterIndex.linkTypes[`${fromId}-${id}`]

                const { top: y1 } = yIndex[fromId]
                const { top: y2 } = yIndex[id]

                const { start: originStart, end: originEnd } = xIndex[fromId]
                const { start: targetStart, end: targetEnd } = xIndex[id]

                const x1 = linkType === 'SS' || linkType === 'SF' ? originStart : originEnd
                const x2 = linkType === 'SS' || linkType === 'FS' ? targetStart : targetEnd

                maxY = Math.max(maxY, y1, y2)

                const display = selectedId === '' ? 'normal' : [id, fromId].includes(selectedId) ? 'highlight' : 'lowlight'

                paths.push({
                    id: `${id}-${fromId}`,
                    d: `M ${x1}, ${y1 + (config.diamondSize / 2) + 2} L ${x2}, ${y2 + (config.diamondSize / 2) + 2}`,
                    display: display
                })

            }
        });


    })

    return (
        <svg
            style={{
                position: 'absolute',
                height: `${maxY + config.diamondSize}px`,
                width: '100vw',
            }}
        >

            <defs>
                <marker
                    id="arrow"
                    viewBox="0 0 10 10"
                    refX="15"
                    refY="5"
                    markerWidth="6"
                    markerHeight="6"
                    orient="auto-start-reverse"
                    fill="context-fill">
                    <path d="M 0 0 L 10 5 L 0 10 z" />
                </marker>
            </defs>

            {paths.map(({ id, d, display }) => (
                <path
                    key={`${id}`}
                    id='arrow-line'
                    markerEnd='url(#arrow)'
                    strokeWidth={display === 'highlight' ? 1.6 : 1}
                    fill='purple'
                    stroke='purple'
                    // strokeDasharray="2,2"
                    opacity={display === 'highlight' ? 1 : display === 'lowlight' ? 0.2 : 0.7}
                    d={d}
                />
            ))}



        </svg>
    )

}