import { Box, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { leftBar } from '../../Layout';
import { laneHeight } from '../../func/processYData';
import { selectElements, selectViewportWidth, selectYIndex } from '../../slice';
import { byggstruktur } from '../../types.d';



export default function ElementSection(props: { id: string }) {

    const yIndex = useSelector(selectYIndex)
    const elements = useSelector(selectElements)
    const viewWidth = useSelector(selectViewportWidth)
    const { id } = props

    if (!(id in yIndex)) {
        return null
    }


    const { color, code, name } = byggstruktur.find(e => e.id === id)!
    const { top, bottom } = yIndex[id]
    const height = (bottom || top + 20) - top


    return (
        <React.Fragment>

            <Box
                position="absolute"
                left={`${leftBar}px`}
                // height={`${config.diamondSize}px`}
                width={`${viewWidth - leftBar}px`}
                top={`${top}px`}
                height={`${height}px`}
            // bgGradient="linear(to-br, gray.100 0%, whiteAlpha.50 30%)"
            // bgGradient="linear(to-r, gray.100 0%, whiteAlpha.50 7%)"

            />


            <Stack
                position="absolute"
                left={`${leftBar}px`}
                top={`${top}px`}
                height={`${height}px`}
                width={`100vw`}
                zIndex={-3}
                justify={"center"}
                p="2"
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                bg={color}
                opacity={0.1}
            />

            <Stack
                position="absolute"
                left={`${leftBar}px`}
                top={`${top}px`}
                height={`${laneHeight}px`}
                // width={`${height}px`}
                zIndex={3}
                justify={"center"}
                p="2"
                whiteSpace={"nowrap"}
                overflow={"hidden"}
            >

                <Text
                    color={color}
                    fontSize={`xs`}
                >{id} - {name} </Text>
            </Stack>

            <Stack
                bg={color}
                position="absolute"
                left={`${0}px`}
                top={`${top}px`}
                height={`${height}px`}
                width={`${leftBar}px`}
                zIndex={3}
                justify={"flex-start"}
                p="2"
                whiteSpace={"nowrap"}
                overflow={"hidden"}
            >

                <Text
                    fontSize={`xs`}
                    fontWeight={600}
                    color="white"
                >{code} </Text>

            </Stack>

        </React.Fragment>
    )

}

