import { Box } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { selectXIndex } from "../../slice";


export default function VerticalBreakIndicator(props: {id: string}) {

    const xIndex = useSelector(selectXIndex)
    const { id } = props
    if (!(id in xIndex)) {
        return null
    }

    const start = xIndex[id].start
    const width = xIndex[id].end - start
    const color = 'gray.500'


    return (
        <React.Fragment>
            <Box
                id="long-line"
                position="absolute"
                left={`${start}px`}
                width={`${width}px`}
                height={"100%"}
                bg={color}
                zIndex={-3}
                opacity={.075}
                // opacity={.20}
            />
        </React.Fragment>
    )
}